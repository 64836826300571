import React, { useEffect, useRef, useState } from "react";

import WaveSurfer from "wavesurfer.js";
import { Icon } from "../Component";
import arrowb from "../../images/arrowb.png";
import arrowf from "../../images/arrowf.png";
import bh from "../../images/bh.png";

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: "#eee",
  progressColor: "OrangeRed",
  cursorColor: "OrangeRed",
  barWidth: 3,
  barRadius: 3,
  responsive: true,
  height: 150,
  normalize: true,
  partialRender: true,
});

export default function WaveForm({ url }) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(0.5);
  //new
  const [speed, setSpeed] = useState(1);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  //   useEffect(() => {
  //     setPlay(false);

  //     const options = formWaveSurferOptions(waveformRef.current);
  //     wavesurfer.current = WaveSurfer.create(options);

  //     wavesurfer.current.load(url);

  //     wavesurfer.current.on("ready", function() {
  //       if (wavesurfer.current) {
  //         wavesurfer.current.setVolume(volume);
  //         setVolume(volume);
  //       }
  //     });

  //     return () => wavesurfer.current.destroy();
  //   }, [url]);

  //   const handlePlayPause = () => {
  //     setPlay(!playing);
  //     wavesurfer.current.playPause();
  //   };

  //new
  useEffect(() => {
    setPlay(false);

    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    wavesurfer.current.load(url);

    wavesurfer.current.on("ready", function () {
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        setVolume(volume);
        setDuration(wavesurfer.current.getDuration());
      }
    });

    wavesurfer.current.on("audioprocess", () => {
      setCurrentTime(wavesurfer.current.getCurrentTime());
    });

    return () => wavesurfer.current.destroy();
  }, [url]);

  const handlePlayPause = () => {
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  useEffect(() => {
    if (wavesurfer.current) {
      wavesurfer.current.on("ready", () => {
        setDuration(wavesurfer.current.getDuration());
      });
    }
  }, [wavesurfer.current]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const toggleSpeed = () => {
    const speeds = [1, 1.25, 1.5, 2];
    const currentIndex = speeds.indexOf(speed);
    const newSpeed = speeds[(currentIndex + 1) % speeds.length];
    setSpeed(newSpeed);
    if (wavesurfer.current) {
      wavesurfer.current.setPlaybackRate(newSpeed);
    }
  };

  const skipBackward = () => {
    if (wavesurfer.current) {
      const newTime = Math.max(0, wavesurfer.current.getCurrentTime() - 15);
      wavesurfer.current.seekTo(newTime / duration);
    }
  };

  const skipForward = () => {
    if (wavesurfer.current) {
      const newTime = Math.min(wavesurfer.current.getDuration(), wavesurfer.current.getCurrentTime() + 15);
      wavesurfer.current.seekTo(newTime / duration);
    }
  };

  const shareToFacebook = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Check out this audio blog!",
          text: "I found this audio blog interesting, have a look!",
          url: window.location.href,
        })
        .catch((error) => console.error("Error sharing", error));
    } else {
      console.error("Share not supported on this browser");
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div>
              <h5>#24 Story Session - Why midlife is the perfect time to "start" anywhere" with Crystal Garrett</h5>
              <small className="text-success">The Midlife Feast</small>
              <div className="row">
                <div className="col-md-3 align-items-center">
                  <div className="" style={{ height: "80%" }}>
                    <img
                      src={bh}
                      alt="blogheadimg"
                      className="img w-100"
                      style={{ height: " -webkit-fill-available", objectFit: "cover" }}
                    />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="d-flex align-items-center">
                    <div className="controls">
                      <button className="playButton" onClick={handlePlayPause}>
                        {!playing ? (
                          <i className="bx bx-play fs-1 ps-1 mt-1"></i>
                        ) : (
                          <i className="bx bx-pause fs-1 mt-1"></i>
                        )}
                      </button>
                    </div>
                    <div id="waveform" ref={waveformRef} className="w-100" />
                  </div>
                  <div className="d-flex justify-content-between row">
                    <div className="d-flex align-items-center col-md-8">
                      <button className="border-0 bg-transparent" onClick={skipBackward}>
                        <img src={arrowb} alt="" />
                      </button>
                      <button className="border-0 bg-transparent mx-1" onClick={skipForward}>
                        <img src={arrowf} alt="" />
                      </button>
                      <button className="border-0 bg-transparent fw-bold fs-5 mx-4" onClick={toggleSpeed}>
                        {speed}x
                      </button>
                      <p className="mb-0 mx-3 ">More Info</p>
                      <p className="mb-0 pt-1" id="cuadro" style={{ cursor: "pointer" }} onClick={shareToFacebook}>
                        <Icon name="share-fill fs-4"></Icon>
                      </p>
                    </div>
                    <div className="text-end opacity-75 col-md-4">
                      {formatTime(currentTime)} | {formatTime(duration)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="d-flex align-items-center">
            <div className="controls">
                <button onClick={handlePlayPause}>
                    {!playing ? <i className="bx bx-play fs-1 ps-1 mt-1"></i> : <i className="bx bx-pause fs-1 mt-1"></i>}
                </button>
            </div>
            <div id="waveform" ref={waveformRef} className="w-100" />
        </div>
        <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
                <button  className="border-0 bg-transparent">
                <img src={arrowb} alt="" />
                </button>
                <button  className="border-0 bg-transparent mx-1">
                <img src={arrowf} alt="" />
                </button>
                <button
                className="border-0 bg-transparent fw-bold fs-5 mx-4"
                >
                </button>
                <p className="mb-0 mx-3 ">More Info</p>
                <p className="mb-0 pt-1" id="cuadro" style={{cursor:'pointer'}}><Icon name="share-fill fs-4"></Icon></p>
            </div>
            <div className="text-end opacity-75">
            </div>
        </div> */}
    </>
  );
}

import React, { useState, useEffect, useContext } from "react";
import Blogdetailcard from "./Blogdetailcard";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getCookie } from "../utils/Utils";
import { CategoryContext } from "../utils/Context";
import { ShimmerPostDetails } from "react-shimmer-effects";
import "./Blog.css";
import "../assets/css/blog.css";

export default function Blogdetails(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [recentPosts, setRecentPosts] = useState([]);
  const [loading, setLoading] = useState(true); // Added loading state
  const [selectedCategory, setSelectedCategory] = useContext(CategoryContext);
  const [categoryCount, setCategoryCount] = useState({});

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        setLoading(true); // Start loading
        const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/public/get-blog`;
        const formData = new FormData();
        if (selectedCategory !== "") {
          formData.append("category_id", selectedCategory);
        }

        formData.append("id", id);
        const response = await fetch(apiUrl, {
          headers: {
            authToken: getCookie("authToken"),
          },
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          if (data.status) {
            setBlog(data.data?.blogs);
          } else {
            console.error("Error fetching blog data", data.message);
          }
        } else {
          console.error("Error fetching blog data", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false); // End loading
      }
    };

    const fetchRecentPosts = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/public/get-list-blogs`;
        const response = await fetch(apiUrl, {
          headers: {
            authToken: getCookie("authToken"),
          },
          method: "POST",
        });

        if (response.ok) {
          const data = await response.json();

          if (data.status) {
            setRecentPosts(data.data.blogs);
          } else {
            console.error("Error fetching recent posts", data.message);
          }
        } else {
          console.error("Error fetching recent posts", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchBlogData();
    fetchRecentPosts();
  }, [id, selectedCategory]);

  useEffect(() => {
    const fetchCategoryCount = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/public/get-category-count`;
        const response = await fetch(apiUrl, {
          headers: {
            authToken: getCookie("authToken"),
          },
          method: "POST",
        });

        if (response.ok) {
          const data = await response.json();
          if (data.status) {
            const categoryCounts = {};
            data.data.forEach((item) => {
              categoryCounts[item.category] = item.count;
            });
            setCategoryCount(categoryCounts);
          } else {
            console.error("Error fetching category count", data.message);
          }
        } else {
          console.error("Error fetching category count", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchCategoryCount();
  }, [selectedCategory]);

  const categories = [
    { id: 0, name: "TECH TRENDS" },
    { id: 1, name: "ARTIFICIAL INTELLIGENCE" },
    { id: 2, name: "PRODUCT REVIEWS" },
    { id: 3, name: "CLOUD COMPUTING" },
    { id: 4, name: "TECH NEWS" },
    { id: 5, name: "TECH CONFERENCES" },
  ];

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    navigate(`/category/${category}`);
  };

  const isVideo = !blog?.blog_image || blog.blog_image === null || blog.blog_image.trim() === "";

  return (
    <div className="blogdetails pb-5 container">
      <div className="row g-3 py-2 justify-content-center my-md-4 my-3">
        <div className="col-md-8 col-12">
          {loading ? (
            <ShimmerPostDetails card cta variant="EDITOR" />
          ) : (
            blog && (
              <Blogdetailcard
                Blogimg={blog.blog_detail_image}
                Blogtitle={blog.title}
                Blogdesc={blog.description}
                BlogVideo={blog.blog_video}
                is_video={isVideo}
                category_id={blog.categories}
              />
            )
          )}
        </div>
        <div className="col-md-4 col-12" id="sidebar">
          <div className="sticky-top row d-flex justify-content-center">
            <div className="">
              <div className="d-flex flex-column px-2 py-1 border rounded-4">
                <button
                  type="button"
                  className="rounded px-2 py-1  mt-2 text-start fw-bold text-light me-auto"
                  style={{
                    background: process.env.REACT_APP_BUTTON_BACKGROUND_COLOR,
                    border: "var(--bs-btn-border-width) solid var(--bs-btn-border-color)",
                  }}
                >
                  Recent Posts
                </button>
                <ul className="list-group my-2 text-start list-group-flush">
                  {recentPosts.map((blog, index) => (
                    <li key={index} className="list-group-item  border-bottom-3">
                      <Link
                        to={`/Blogdetails/${blog.id}`}
                        className={`text-decoration-none ${process.env.REACT_APP_TEXT_COLOR}`}
                        style={{ textAlign: "justify" }}
                      >
                        {blog.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="d-flex flex-column px-2 py-2 border rounded-4 mt-4">
                <button
                  type="button"
                  className="rounded px-2 py-1 mt-2 text-start fw-bold text-light me-auto"
                  style={{
                    background: process.env.REACT_APP_BUTTON_BACKGROUND_COLOR,
                    border: "var(--bs-btn-border-width) solid var(--bs-btn-border-color)",
                  }}
                >
                  Categories
                </button>
                <ul className="list-group my-2 text-start list-group-flush">
                  {categories.map((category, index) => (
                    <li
                      key={index}
                      className="list-group-item my-3 rounded border-bottom-0"
                      style={{
                        borderLeft: `3px solid ${process.env.REACT_APP_SIDEBAR_LIST_LEFT_BORDER_COLOR}`,
                        backgroundColor: process.env.REACT_APP_SIDEBAR_LIST_BACKGROUND_COLOR,
                      }}
                    >
                      <p
                        className={`text-decoration-none ${process.env.REACT_APP_TEXT_COLOR}`}
                        onClick={() => handleCategoryClick(category.name)}
                        style={{ textAlign: "justify", cursor: "pointer" }}
                      >
                        {category.name} ({categoryCount[category.name] || 0})
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Blogdetailsfoot">
        <h5 className="p-2">
          <Link to="/" className="text-light">
            Explore More Blogs from {process.env.REACT_APP_PROJECT_NAME_BLOG} Blogs...!
          </Link>
        </h5>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useTheme, useThemeUpdate } from "../provider/Theme";
import classNames from "classnames";
import User from "./dropdown/user/User";
import AppDropdown from "./dropdown/app/App";
import logoLight from "../../images/personaimg/Persona_Digest_dark.png";
import logoDark from "../../images/personaimg/Persona_Digest_light.png";
import { Icon } from "../../Components/Component";
import Notification from "./dropdown/notification/Notification";
import { getCookie } from "../../utils/Utils";
import Cookies from "js-cookie";
import { postRequest } from "../../utils/api-header";
import { NewsContext } from "../common/navbar/context/NewsContext";

import SpeechMic from "./dropdown/microphone/SpeechMic";

const SiloHeader = ({ fixed, className }) => {
    const theme = useTheme();
    const [sticky, setSticky] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [fullScreenOn, setFullScreenOn] = useState(false);
    const { setDataSearch } = useContext(NewsContext);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const { skin } = useTheme();
    const themeUpdate = useThemeUpdate();

    useEffect(() => {
        const isAuthenticated = !!getCookie("authToken");
        setAuthenticated(isAuthenticated);
    }, []);

    useEffect(() => {
        const fetchTheme = async () => {
            try {
                const response = await postRequest("api/v1/public/frontend-settings");
                if (response?.status) {
                    const fetchedTheme = response?.data?.frontend_settings?.theme;
                    themeUpdate.skin(fetchedTheme === "1" ? "light" : "dark");
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchTheme();
    }, [fullScreenOn]);

    const headerClass = classNames({
        "nk-header": true,
        "nk-header-fixed": fixed,
        "has-fixed": sticky,
        [`is-light`]: theme.header === "white",
        [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
        [`${className}`]: className,
    });

    useEffect(() => {
        let _item_offset = 30;
        if (window.scrollY > _item_offset) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    }, [])

    const toggleFullScreen = () => {
        setFullScreenOn(!fullScreenOn);
        if (
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement
        ) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        } else {
            const element = document.documentElement;
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            }
        }
    };

    const [time, setTime] = useState("");
    const [date, setDate] = useState("");

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            const hours = now.getHours();
            const minutes = now.getMinutes();
            const ampm = hours >= 12 ? "PM" : "AM";
            const formattedHours = hours % 12 || 12;
            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

            setTime(`${formattedHours}:${formattedMinutes} ${ampm}`);

            const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            const day = days[now.getDay()];
            const date = now.getDate();
            const month = now.getMonth() + 1;
            const year = now.getFullYear();

            setDate(`${day}, ${month}/${date}/${year}`);
        };

        updateTime();
        const interval = setInterval(updateTime, 1000);

        return () => clearInterval(interval);
    }, []);

    const handleSignIn = () => {
        const now = new Date();
        const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

        Cookies.set("currentURL", window.location.href, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
        });
        const logoutUrl = `${process.env.REACT_APP_ACCOUNT_URL}logout`;
        window.location.href = logoutUrl;
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setDataSearch(searchTerm);
            navigate(`/search/${searchTerm}`);
            setSearchTerm('');
        }
    };

    return (
        <>
            <section className="section-header">
                <div className={`${headerClass} border-1`}>
                    <div className="container-fluid position-relative p-1">
                        <div className="nk-header-wrap align-items-center justify-content-around justify-content-md-between">
                            {/* <div className="nk-menu-trigger d-xl-none ms-n1">
                                <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="menu" click={themeUpdate.sidebarVisibility} />
                            </div> */}
                            <div className="nk-header-app-name">
                                <NavLink to="/" className="scrollto">
                                    <div className="d-flex align-items-center">
                                        <div className="nk-header-logo">
                                            <img className="header-logo-img" src={theme.skin === "dark" ? logoLight : logoDark} alt="Logo" style={{ height: "40px" }} />
                                        </div>
                                    </div>
                                </NavLink>
                                <div className="form-control-wrap ms-md-4 ms-1 header-search">
                                    <div className="form-icon form-icon-left">
                                        <Icon name="search" />
                                    </div>
                                    <input className="form-control" type="text" id="appsearch" placeholder="Search" style={{ borderRadius: '24px' }} value={searchTerm} onChange={handleSearchChange} onKeyPress={handleKeyPress} />
                                    <div className="form-icon form-icon-right rounded-5">
                                        <SpeechMic />
                                    </div>
                                </div>
                            </div>
                            <div className="d-lg-flex justify-content-center d-none header-calendar">
                                <div style={{ lineHeight: "17px" }}>
                                    <p className="mb-0 fw-bolder text-blue-color ff ">{time}</p>
                                    <p className="fs-9px mb-0 ff">{date}</p>
                                </div>
                                <div className="ms-1">
                                    <Link to="https://calendar.silocloud.io/">
                                        <Icon name="calender-date" className="head-icon py-3 fs-1" />
                                    </Link>
                                </div>
                            </div>
                            <div className="nk-header-tools nk-header-app-name align-items-center py-0 py-md-1 m-0">
                                <ul className="nk-quick-nav me-1">
                                    <li className="mt-1 d-md-block d-none">
                                        {fullScreenOn ? (
                                            <Icon name="minimize" className="pointer fs-4" onClick={toggleFullScreen} />
                                        ) : (
                                            <Icon name="maximize" className="pointer fs-4" onClick={toggleFullScreen} />
                                        )}
                                    </li>
                                    <li className="notification-dropdown d-block">
                                        <Notification />
                                    </li>
                                    <li className="notification-dropdown">
                                        <AppDropdown />
                                    </li>
                                    {!authenticated ? (
                                        <Link to={`${process.env.REACT_APP_ACCOUNT_URL}`}>
                                            <li className="d-flex justify-content-end align-items-center ">
                                                <button className="btn sign-in-btn h-25" onClick={handleSignIn}>
                                                    Sign In
                                                </button>
                                            </li>
                                        </Link>
                                    ) : (
                                        <li className="user-dropdown d-flex">
                                            <User />
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SiloHeader;
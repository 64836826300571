import React, { useEffect, useState } from "react";
import { getCookie } from "../utils/Utils";
import { UserAvatar } from "../Components/Component";
import userprofile from "../images/icons/profile.svg";
const AccountAvatar = ({ profileName }) => {
  const [isAvatarImage, setIsAvatarImage] = useState(false);
  const [imageUrl, setFileURL] = useState(null);
  useEffect(() => {
    getProfileImage();
  }, []);
  const getProfileImage = async () => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}api/v1/account/get-only-profile-img`, {
      method: "POST",
      cache: "no-store",
      headers: {
        authToken: getCookie("authToken", null),
      },
    });
    if (res.ok) {
      const blob = await res.blob();
      if (blob) {
        const imageUrl = URL.createObjectURL(blob);
        if (imageUrl) {
          setIsAvatarImage(true);
          setFileURL(imageUrl);
        } else {
          setIsAvatarImage(false);
          setFileURL(null);
        }
      }
    }
  };
  if (isAvatarImage) return <UserAvatar image={imageUrl} imageAlt={`profile-img`} theme="primary" />;
  return <UserAvatar image={userprofile} className="bg-transparent" />;
};

export default AccountAvatar;
